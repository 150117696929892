import React from 'react'
import PackageRegPage from './_package.js'
import { SponsorPackageItems } from '../../components/page/package/elements'

export default function SponsorPackagePage() {
  return (
    <PackageRegPage
      heading={'Sponsor Package'}
      jotformIds={{ US: '210978774782170', CA: '210978607286164' }}
      text={<SponsorPackageItems />}
    />
  )
}
